@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans-Regular.eot?") format("eot"), 
	url("../fonts/OpenSans-Regular.woff") format("woff"),
	 url("../fonts/OpenSans-Regular.ttf") format("truetype"), 
	 url("../fonts/OpenSans-Regular.svg#OpenSans") format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Open Sans Bold";
	src: url("../fonts/OpenSans-Bold.eot?") format("eot"), 
	url("../fonts/OpenSans-Bold.woff") format("woff"), 
	url("../fonts/OpenSans-Bold.ttf") format("truetype"), 
	url("../fonts/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
	font-weight: normal;
	font-style: normal;
}