@import "~bootstrap/scss/bootstrap";
@import "./reset.scss";
@import "./variables.scss";
@import "./font.scss";
@import "./mixin.scss";
::-webkit-scrollbar {
  width                : 6px;
  height               : 6px;
  background-color     : $color-secondary;
  -webkit-border-radius: 4px;
  -moz-border-radius   : 4px;
  border-radius        : 0
}

::-webkit-scrollbar-thumb {
  background-color     : $color-third;
  -webkit-border-radius: 4px;
  -moz-border-radius   : 4px;
  border-radius        : 0
}

#root,
.App{height: 100%;}
.app-header {
  background-color: rgba($color: #fff, $alpha: 1);
  backdrop-filter: blur(8px);
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 99;
  .top-left-row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
  }
  .logo{
      width: 100%;
      height: 34px;;
      font-size: 0;
      background: url('../images/logo.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
  }
  .nav-list{
    &.show{
      transform: translateX(0);
    }
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    left: 0;
    top: 0;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 0 0 30px;
    transform: translateX(105%); 
      transition: .5s;
    ul{
      padding-top: 80px;
    }
    &::before{
      content: "";
      display: block;
      width: 50%;
      height: 34px;;
      font-size: 0;
      background: url('../images/logo.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
    }
    .nav-item{
      height: 3rem;
      width: 100%;
      margin-left: auto;
      margin-bottom: 20px;
      .nav-item-link{
        padding-left: 30px;
        display: block;
        float: right;
        width: 100%;
        height: 50px;
          line-height: 50px;
          background: url('../images/bg-nav.png') no-repeat right center; background-size: 100% 100%;
      }
      
      &.current{
        .nav-item-link{
          background: url('../images/bg-nav-hover.png') no-repeat right center;background-size: 100% 100%;
          font-weight: bold;
        }
      }
      
    }
    .btn-close{
      box-sizing: border-box;
      position: absolute;top:20px;right: 20px; width: 36px; height: 26px; overflow: hidden; 
    white-space: nowrap;z-index: 101;
    padding: 0;
    background: linear-gradient(270deg, #B5F5E7 0%, #F2F0AA 100%);
    border-radius: 4px;
    border: 1px solid #00BCD4;
      span { position: absolute; display: inline-block; height: 2px; width:12px; background: rgba(0, 188, 212, 1); top: 50%; right: 11px; }
      span::before ,span::after { content: " "; position: absolute; display: inline-block; height: 2px; width:12px; background: rgba(0, 188, 212, 1); top: 4px;}
      span::after { top: -4px; }
      span { height: 0; }
      span::before { transform: rotate(-45deg);  top: 0; }
      span::after { transform: rotate(45deg);  top: 0; }
    }
    .copyright{
      position: absolute;
      left: 30px;
      bottom: 2rem;
      font-size: 12px;
      color: rgba(0, 51, 51, .3);
    }
  }
  .app-header-right{
    height: 36px;
    display: flex;
    justify-content:flex-end;
    align-items: center;
  }
  .topbar-btn{position: relative;float: right; width: 36px; height: 26px; overflow: hidden; 
    white-space: nowrap;z-index: 101;
    background: linear-gradient(270deg, #B5F5E7 0%, #F2F0AA 100%);
    border-radius: 4px;
    border: 1px solid #00BCD4;}
    
  .app-header-language{
    display: none;
      span{
        color:rgba(0, 188, 212, 1);
        display: block;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
      }
  }
  .hdBtn {margin-left: 10px;
      span { position: absolute; display: inline-block; height: 2px; width:12px; background: rgba(0, 188, 212, 1); top: 50%; right: 11px; margin-top: -2px; }
      span::before ,span::after { content: " "; position: absolute; display: inline-block; height: 2px; width:12px; background: rgba(0, 188, 212, 1); top: 4px; transition: all .5s; }
      span::after { top: -4px; }
  }
  
}

// .panel{ height:calc(100% - 64px);padding-top: 64px;}
.panel{ height:100%;padding-top: 64px;position: relative;overflow: hidden;}

.panel-text{
  position: relative;
  h1{
    width: 100%;
    color: $color-primary;
    font-family: "Open Sans Bold";
    font-weight: bold;
    font-size: 34px;
    text-transform: uppercase;
    line-height: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    z-index: 8;
  }
  p{
    color: $color-primary-dark;
    font-family: "Open Sans";
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;
    position: relative;
    z-index: 8;
  }
  .panel-button-wrap {
    position: relative;
    z-index: 8;
    .panel-button{
      cursor: pointer;
      margin-top: 30px;
      display: block;
      font-weight: 600;
      background: linear-gradient(270deg, #24E5CB 0%, #C4D936 100%);
      border-radius: 16px;
      width: 140px;
      height: 48px;font-family: "Open Sans";
      -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
      @include lhtac(48px);
      color: $color-primary;
      &:hover{
        background: linear-gradient(90deg, #24E5CB 0%, #C4D936 100%);
        border: none;
      }
    }
  }
}

.panel-1{
  background: $color-white url('../images/h5-bg-p1.png') no-repeat right 80%;
  background-size: auto 70%;
  overflow: hidden;
  position: relative;
  min-height: 100%;
  height: unset;
  h1{margin-top: 10%;}
  .panel-text{
    padding: 0 30px;
    &::before{
      content: "";
      position: absolute;
      top: 10rem;
      left: -8rem;
      width: 16rem;
      height: 16rem;
      background: radial-gradient( rgba(255, 246, 168, 1), rgba(255, 246, 168, .3));
      border-radius: 50%;
      @include blur(30px);
    }
    &::after{
      content: "";
      position: absolute;
      top: -4rem;
      right: -2rem;
      width: 22rem;
      height: 22rem;
      background: radial-gradient( RGBA(204, 244, 210, 1) 0%, RGBA(204, 244, 210, .6) 50%,RGBA(204, 244, 210, .4) 100%);
      border-radius: 50%;
      @include blur(50px);
    }
  }
  .panel-img{
    position: relative;
    margin-top: 2rem;
    height: 60vh;
    .mg{
      position: absolute;
      &.mg1{@include bg-image('../images/mg_1'); width: 9rem;height: 9rem;left: 50%;top:7rem;transform: translateX(-50%);}
      &.mg2{@include bg-image('../images/mg_2'); width: 6rem;height: 6.6rem;left: 20px;top:1rem}
      &.mg3{@include bg-image('../images/mg_3'); width: 6rem;height: 6.6rem;left: 20px;top:50%}
      &.mg4{@include bg-image('../images/mg_4'); width: 6rem;height: 6.7rem;right: 25%;bottom:2rem}
      &.mg5{@include bg-image('../images/mg_5'); width: 7rem;height: 7.6rem;right: 30px;top:2rem}
    }
    .pot{
      display: block;
      position: absolute;
      background:url('../images/pot.png') no-repeat;
      background-size:contain;
      &.pot1{width: 2rem; height: 2rem;right: 10%;top:5px;}
      &.pot2{width: 2.5rem; height: 2.5rem;right: 5%;top:10rem;opacity: .6;}
      &.pot3{width: 1rem; height: 1rem;right: 16%;top:12rem;opacity: .8;}
      &.pot4{width: 2rem; height: 2rem;left: 20%;top:42%;}
      &.pot5{width: 1.4rem; height: 1.4rem;left: 20px;top:36%;}
      &.pot6{width: 4rem; height: 4rem;left: 22%;bottom:2rem;}
    }
  }
}

.panel-2{
  background:url('../images/h5-city.png') no-repeat right center,
   linear-gradient(325deg, $color-secondary 0%,$color-secondary 30%, #afd63d 100%),
   linear-gradient(145deg, #1ED290 0%, #FFD500 100%);
   &::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(30% + 4rem);
    background: linear-gradient(0deg, #1ED290 0%, #FFD500 100%);
    @include blur(50px);
  }
  .container{
    height: 100%;
    .row:last-child{
      width: 100vw;
      position: absolute;
      bottom: 4rem;
      margin: 0;
      right: 0;
      height: 30%;
      @include flex(column,space-around,center);
     
    }
    .link-button{
      width: 240px;
      position: relative;
      z-index: 3;
      height: 3.75rem;
      line-height: 3.75rem;
      background: rgba(255,255,255,.5);
      border-radius: 45px;
      display: inline-flex;
        align-items: center;
        padding-left: 2.5rem;
      .icon{
        width: 2rem;
        height: 2rem;
        margin-right: 1.2rem;
        &.icon-1{@include bg-image('../images/Github2');}
        &.icon-2{@include bg-image('../images/Tool');}
        &.icon-3{@include bg-image('../images/Document');}
      }
    }
  }
  .panel-text{
    h1{
      color: $color-white;
    }
    p{
      color: $color-white;
      opacity: 0.6;
    } 
  }
  .panel-img{
    .person{
      background: url('../images/y.png') no-repeat;
      background-size: contain;
      width: 8.6rem;
      height: 9rem;
      float: right;
    }
  }
}

.panel-3{
  background: white url('../images/h5-bg-p3.png') no-repeat right bottom; background-size: auto 80%;
  .container{height: 100%;}
  .orderswap-title{
    img{
      width: 149px;
      height: 50px;
      @media (min-width:768px) {
        width: 297px;
        height: 102px;
      }
    }
  }
  .container-inner{
    height: 86%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    .row:last-child{
      height: 84%;
      justify-content: space-between;
    }
  }
  .panel-img{
    justify-content: center;
    align-items: center;
  }
  .swap-img{
    width: 5.5rem;
    height: 6.5rem;
    margin: 0 auto .5rem;
    &.avatar1{    
      @include bg-image('../images/orderswap_1');
    }
    &.avatar2{    
      @include bg-image('../images/orderswap_2');
    }
    &.avatar3{    
      @include bg-image('../images/orderswap_3');
    }
  }
  strong{font-weight: bold;font-size: 1.1rem;font-family: "Open Sans Bold";
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    color: $color-primary;margin-bottom: 1.5rem;display: block;}
}

.panel-4{
  background:url('../images/h5-bg-p4-l.png') no-repeat top left,
  url('../images/h5-bg-p4-r.png') no-repeat bottom right,
   linear-gradient(180deg, #E4FCF3 0%, #FFFFFF 100%);
   background-size: 50% auto, 50% auto, 100% 100%;
   .container{height: 100%;}
   .row{
     height: 90%;
   }
  .gaga-img{
    width: 8rem;
    height: 10rem;
    margin: 0 auto .5rem;
    &.airdrop{    
      @include bg-image('../images/gaga-airdrop');
    }
    &.entrepot{    
      width: 7rem;
      height: 10rem;
      @include bg-image('../images/gaga-entrepot');
      background-position: center;
    }
  }
  
  strong{font-weight: bold;font-size: 1.2rem;font-family: "Open Sans Bold";-webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;color: $color-primary;}
}

.app-footer{
  position: relative;
  width: 100%;
  height: 200px;
  background: linear-gradient(325deg, $color-secondary 0%,$color-secondary 30%, #afd63d 100%);
  text-align: center;
  padding-top: 2.5rem;
  .container{height: calc(200px - 2.5rem);}
  .row{
    height: 100%;
    @include flex(row,center,center,wrap);
    
  }
  .copyright{
    order: 2;
    font-size: 14px;
    color: $color-primary-dark;
  }
}
.social-media{
  display: flex;
  order: 1;
  // justify-content: space-around;
  justify-content: center;
  align-items: center;
  .icon{
    width: 40px;
    height: 40px;
    font-size: 0;
    margin: 0 .5rem;
  }
  .divider{
    background: #fff;
    margin: 0 .5rem;
    height: 20px;
    width: 1px;

  }
  .icon-twitter{
    @include bg-image('../images/Twitter');
  }
  .icon-medium{
    @include bg-image('../images/Medium');
  }
  .icon-github{
    @include bg-image('../images/Github');
  }
  .icon-discord{
    @include bg-image('../images/Discord');
  }
  .icon-press{
    @include bg-image('../images/press');
  }
}
.line-light{
  height    : 1px;
  width     : 100%;
  background: radial-gradient(closest-side, #999999, rgba(153, 153, 153, 0));
}

@media (min-width: 768px) {
  .app-header {
    background-color: rgba($color: #fff, $alpha: .8);
    // height: 160px;
    .top-left-row{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 34px;
    }
    .logo{
        width: 132px;
        height: 34px;;
        display: block;
        font-size: 0;
        background: url('../images/logo.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .nav-list{
      padding: 0;
      float: left;
      position: relative;
      width: auto;
      height: unset;
      margin-left: 40px;
      background: none;
      transform: none; 
      &::before,.btn-close{display: none;}
    
      ul{padding: 0; @include flex(row,center,center);}
      .nav-item{
        padding: 0 30px;
        height: auto;
        width: auto;
        margin: 0;
        &:first-child{
          display: none;
        }
        .nav-item-link{
          color: #242B35;
          font-size: 16px;
          transition: all .3s;
          padding-left: 0;
          display: inline;
          float: none;
          line-height: unset;
          border: none;
          background: none;
          border-radius: 0;
          @include no-wrap;
          &:hover{
            color: #00BCD4;
            @include hoverLine(2px,#00BCD4);
            &::before{
              content: "";
              position: absolute;
              width: 100%;
              height: 80%;
              background: linear-gradient(270deg, #24E5CB 0%, #FFD500 100%);
              opacity: 0.5;
              filter: blur(10px);
            }
          }
        }
        
        &.current{
          .nav-item-link{
            color: #00BCD4;
            background: none;
            @include hoverLine(2px,#00BCD4);
            font-weight: normal;
            &::before{
              content: "";
              position: absolute;
              width: 100%;
              height: 80%;
              background: linear-gradient(270deg, #24E5CB 0%, #FFD500 100%);
              opacity: 0.5;
              filter: blur(10px);
            }
          }
        }
      }
      .copyright{display: none;}
    }
    .hdBtn{
      display: none;
    }
  }

  .panel{
    min-height: unset;
    height: 800px;
    .container{height: 100%;}
  }
  .panel-text{
    h1{
      font-size: 56px;
      margin-bottom: 20px;
    }
    p{
      font-size: 20px;
    }
  }

  .panel-1{
    background: $color-white url('../images/bg-p1.png') no-repeat right top;
    background-size:auto 680px;
    .row{
      height: 100%;
      @include flex(row, center, flex-start);
    }
    h1{margin-top: 0;}
    .panel-text{
      padding-top: 180px;
      // height: 100%;
      // display: inline-flex;
      // align-items: center;
      // h1{margin-top: -50%;}
    }
    .panel-img{
      height: 100%;
      .mg{
        position: absolute;
        &.mg1{@include bg-image('../images/mg_1'); width: 200px;height: 192px;left: 55%;top:calc(333px - 84px);transform: translateX(-50%);}
        &.mg2{@include bg-image('../images/mg_2'); width: 111px;height: 120px;left: 80px;top:89px}
        &.mg3{@include bg-image('../images/mg_3'); width: 91px;height: 100px;left: -10px;top:400px}
        &.mg4{@include bg-image('../images/mg_4'); width: 139px;height: 153px;left: 390px;top:420px}
        &.mg5{@include bg-image('../images/mg_5'); width: 239px;height: 346px;left: 400px;top:120px}
      }
      .pot{
        display: block;
        position: absolute;
        background:url('../images/pot.png') no-repeat;
        background-size:contain;
        &.pot1{width: 23px; height: 26px;right: 10%;top:27px;}
        &.pot2{width: 54px; height: 58px;right: 20%;top:30px;opacity: .6;}
        &.pot3{width: 66px; height: 73px;right: 0;top:320px;}
        &.pot4{width: 24px; height: 26px;left: 0;top:280px;}
        &.pot5{width: 40px; height: 43px;left: 120px;top:330px;}
        &.pot6{width: 85px; height: 95px;left: 115px;top:460px;}
      }
    }
  }
  .panel-2{
    background:url('../images//bg-p2.png') no-repeat left bottom, linear-gradient(125deg, #afd63d 0%,#afd63d 10%,$color-secondary  100%);
    &::before{
      height: 150px;
      background: linear-gradient(245deg, #1ED290 0%, #FFD500 100%);
    }
    .container{
      background:url('../images/city.png') no-repeat right 80px;
      background-size: auto;
      .row:first-child{
        height: 80%;
        @include flex(row,center,center,wrap);
      }
      .row:last-child{
        width: auto;
        position: relative;
        bottom: 0;
        margin: 0;
        right: 0;
        height: 120px;
        @include flex(row,space-around,flex-start);
        
      }
    }
    .panel-img{
      .person{
        background: url('../images/y.png') no-repeat;
        background-size: contain;
        width: 245px;
        height: 255px;
        float: right;
      }
    }
  }
  .panel-3{
    background: $color-white url('../images/bg-p3.png') no-repeat center bottom; background-size: auto 98%;
    padding-top: 150px;
    .container-inner{
      height: 505px;
      justify-content:space-between;
      .row:last-child{
        height: unset;
      }
    }
    .row{ 
      @include flex(row,center,center,wrap)
    }
  }
  .panel-4{
    background:url('../images/bg-p4-l.png') no-repeat top left,
    url('../images/bg-p4-r.png') no-repeat bottom right,
     linear-gradient(180deg, #E4FCF3 0%, #FFFFFF 100%);
    background-size: 502px 390px,452px 389px, 100% 100%;
    .row{
      height: 80%;
      @include flex(row, center, center,wrap);
    }
    
  }

  .app-footer{
    background: linear-gradient(125deg, #afd63d 0%,#afd63d 10%,$color-secondary  100%);
    .row{ 
      @include flex(row,center,center,wrap)
    }
    .copyright{text-align: left;order: 0;}
    .social-media{
      justify-content: end;
      
    }
  }
}