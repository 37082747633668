@import "./mixin.scss";
@media (min-width: 768px){
  .press-wrap{
    .app-header{
      height: 160px;
      position: relative;
    }
  }
  
}
.press-wrap{
  padding-bottom: 80px;
}
.mybreadcrumb{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  a{
    color:rgba(0, 188, 212, 1);
  }
  span{
    padding-left: 5px;
    background: url('../images/arrow_r@2x.png') no-repeat left center;
    padding-left: 24px;
    
  }
}

.press-content{
  padding-top: 60px;
  margin-bottom: 80px;
  h1{
    font-size: 40px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: bold;
    color: #003333;
    margin-bottom: 16px;
  }
  h2{
    color: rgba(0, 51, 51, .6);
    font-family: OpenSans-Bold, OpenSans;
    font-size: 20px;
    margin-bottom: 40px;
  }
  .img-logo {
    display: block;
    width: 480px;
    background-color: #fff;
    text-align: center;
    height: 220px;
    margin-bottom: 10px;
    transition: all .3s;
    .img-w{
      height: 155px;
      width: 100%;
      display: inline-flex;
      img{margin: auto;}
    }
    div:last-child {
      background-color: #fff;
      text-align-last: left;
      color: rgba(0, 51, 51, .6);
      width: 100%;
      height: 65px;
      padding: 20px 30px;
      .icon-download{
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        margin-left: 28px;
        @include bg-image('../images/arrow')
      }
    }
    &:hover{
      background-color: #fafafa;
      box-shadow: rgba(0, 0 ,0 ,0.1) 0 0 8px;
    }
  }
  
}